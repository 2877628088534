import { useState } from 'react';

import {useApplicationPage} from '../../../hooks/useApplicationPage.js'
import Push from '../modal/push';

import './header.scss'


const Header = () => {
    const {scrollToBlock, selectedModalStatus, modal} = useApplicationPage()
    const {0: isMenu, 1: setIsMenu} = useState(false)
    const handleMenuActive = () => {
        setIsMenu(!isMenu)
    }
    const clazz = '.pr-bg'
    return (
        <div>
            <header className="header">
                <div className='container'>
                    <div className='header__inner'>
                        <a className="header__logo" href='https://premboost.ru/'><span>PREM</span>BOOST</a>
                        <nav className='header__nav'>
                            <a href='https://t.me/suponls24_bot' rel="nofollow noreferrer" target="_blank">Поддержка</a>
                            <a href='https://vk.com/topic-229107739_53171317' rel="nofollow noreferrer" target="_blank">Отзывы</a>
                            <a href='https://vk.com/club229107739' rel="nofollow noreferrer" target="_blank">Сообщество</a>
                            <a href='/' onClick={e => scrollToBlock(e,clazz)}>Гарантии</a>
                        </nav>
                        <span className='header__inner--mypush-link' onClick={e => selectedModalStatus(e)}>
                            <span>
                                <svg viewBox="0 0 26 26" fill="none" width="14" xmlns="http://www.w3.org/2000/svg"> <path d="M7.8 20.8C6.37 20.8 5.2 21.97 5.2 23.4C5.2 24.83 6.37 26 7.8 26C9.23 26 10.4 24.83 10.4 23.4C10.4 21.97 9.23 20.8 7.8 20.8ZM0 0V2.6H2.6L7.28 12.48L5.46 15.6C5.33 15.99 5.2 16.51 5.2 16.9C5.2 18.33 6.37 19.5 7.8 19.5H23.4V16.9H8.32C8.19 16.9 8.06 16.77 8.06 16.64V16.5099L9.23 14.2999H18.85C19.89 14.2999 20.67 13.7799 21.06 12.9999L25.74 4.55C26 4.29 26 4.16 26 3.9C26 3.12 25.48 2.6 24.7 2.6H5.46L4.29 0H0ZM20.8 20.8C19.37 20.8 18.2 21.97 18.2 23.4C18.2 24.83 19.37 26 20.8 26C22.23 26 23.4 24.83 23.4 23.4C23.4 21.97 22.23 20.8 20.8 20.8Z" fill="#CFD4DA"></path> </svg>
                            </span>
                        </span>
                        <div className='burger' onClick={handleMenuActive}>
                            { isMenu ? 
                            <svg fill="#CFD4DA" height="24px" width="24" id="Layer_1" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" ><path className='svg-anim' d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/></svg>
                            :
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className='svg-anim' xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 12H21" stroke="#CFD4DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3 6H21" stroke="#CFD4DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3 18H21" stroke="#CFD4DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            }
                        </div>
                        {isMenu && (
                            <div className="menu">
                                <a href='https://t.me/suponls24_bot' rel="nofollow noreferrer" target="_blank">Поддержка</a>
                                <a href='https://vk.com/topic-229107739_53171317' rel="nofollow noreferrer" target="_blank">Отзывы</a>
                                <a href='https://vk.com/club229107739' rel="nofollow noreferrer" target="_blank">Сообщество</a>
                                <a href='/' onClick={e => scrollToBlock(e,clazz)}>Гарантии</a>
                            </div>
                        )}
                    </div>
                </div>
            </header>
            <Push 
                modal={modal}
                selectedModalStatus={selectedModalStatus}/>
        </div>
    )
}


export default Header